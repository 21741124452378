//node modules
import React, {useMemo} from "react";
import {Colors} from "../../styles/colors.style";
import {PortalUrl} from "../../global/variables";

//hooks & functions

//components

//validators
type Props = {};

const Footer: React.FC<Props> = () => {
    const year = useMemo(() => new Date().getFullYear(), []);

    return (
        <footer className={"footer-wrapper"} style={styles["footerWrapper"]}>
            <a href={PortalUrl}>
                <img className={"img-fluid footer-logo"} src={"/img/logo-dark.png"} alt={"Styreportalen"} />
            </a>
            <span className={"footer-copyright"}>&copy; {year} - Styreportalen AS</span>
        </footer>
    );
};

export default Footer;

const styles = {
    footerWrapper: {
        backgroundColor: Colors.footerBackground,
    },
} as Record<string, React.CSSProperties>;
