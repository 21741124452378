import React, {ReactElement, Suspense} from "react";
import PageLayout from "./layout/pageLayout";
import {HelmetProvider} from "react-helmet-async";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {Route, Routes, useLocation} from "react-router-dom";
import {routes} from "./Routes";
import {library} from "@fortawesome/fontawesome-svg-core";
import {fab} from "@fortawesome/free-brands-svg-icons";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {far} from "@fortawesome/free-regular-svg-icons";
import moment from "moment-timezone";
import {PageLoader} from "./components/loaders/PageLoader";
moment.tz.setDefault("Europe/Oslo");

library.add(fab, fas, far);

function App() {
    const location = useLocation();
    const currentKey = location.pathname.split("/")[1] || "/";
    const timeout = {enter: 500, exit: 500};
    const animationName = "rag-fadeIn";

    return (
        <HelmetProvider>
            {/*@ts-ignore*/}
            <TransitionGroup>
                {/*@ts-ignore*/}
                <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                    <Suspense fallback={<PageLoader />}>
                        <PageLayout pageLinks={routes}>
                            <Routes>
                                {routes.reduce((pageList: ReactElement[], page, i) => {
                                    const route = getPageRoutes(false, page, `${i}`);

                                    if (Array.isArray(route)) {
                                        pageList = pageList.concat(route);
                                    } else if (route) {
                                        pageList.push(route);
                                    }

                                    return pageList;
                                }, [])}
                            </Routes>
                        </PageLayout>
                    </Suspense>
                </CSSTransition>
            </TransitionGroup>
        </HelmetProvider>
    );
}

export default App;

function getPageRoutes(filterAuth: boolean, page: any, pageSection?: string) {
    if (page?.subPages) {
        return page.subPages.reduce((pageList: ReactElement[], page: any, i: number) => {
            const route = getPageRoutes(filterAuth, page, `${page.title}-${i}`);

            if (Array.isArray(route)) {
                pageList = pageList.concat(route);
            } else if (route) {
                pageList.push(route);
            }

            return pageList;
        }, []);
    }

    if (filterAuth && !page.needAuth) {
        return <Route path={page.link} element={<page.element />} key={"page" + pageSection} />;
    } else if (!filterAuth) {
        return <Route path={page.link} element={<page.element />} key={"page" + pageSection} />;
    }
}
