//node modules
import {lazy} from "react";
import Index from "./pages/result";

//errorPages-pages

//icons

//pages
//errorPages pages
const Error404 = lazy(() => import("./pages/errorPages/notFound"));

//lazy-pages
const Landing = lazy(() => import("./pages/landing"));
const FormPage = lazy(() => import("./pages/form"));

//Router structure
const errorPages = [
    {
        link: "/not_found",
        element: Error404,
        page: "Side ikke funnet",
        icon: "",
    },
    {
        link: "*",
        element: Error404,
        page: "Side ikke funnet",
        icon: "",
    },
];

const landingPages = [
    {
        link: "/",
        element: Landing,
        page: "Hjem",
        icon: "",
    },
];

const formPages = [
    {
        link: "/:orgKey",
        element: FormPage,
        page: "Innmelding",
        icon: "",
    },
    {
        link: "/:orgKey/:formId",
        element: FormPage,
        page: "Innmelding",
        icon: "",
    },
    {
        link: "/:orgKey/:formId/:applicationId",
        element: FormPage,
        page: "Innmelding",
        icon: "",
    },
];

const confirmationPages = [
    {
        link: "/sent",
        element: Index,
        page: "Bekreftelse",
        icon: "",
    },
];

export const routes = [
    //errorPages pages
    ...errorPages,

    //pages
    ...landingPages,
    ...formPages,
    ...confirmationPages,
];
