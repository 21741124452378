import React from "react";
import BasePage from "../../layout/BasePage";
import styled from "styled-components";
import SPLoader from "./SPSpinner";

export const LogoSVG = styled.object`
    max-height: 5rem;
    width: auto;
`;

export const PageLoader: React.FC = () => {
    return (
        <BasePage>
            <div
                style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    display: "flex",
                    width: "100vw",
                    maxWidth: "100%",
                    height: "100vh",
                    maxHeight: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <SPLoader type={"dots"} />
            </div>
        </BasePage>
    );
};
