import PageContainer from "./PageContainer";
import Footer from "./footer/Footer";
import TopNav from "./navigation/TopNav";
import {Helmet} from "react-helmet-async";
import i18n from "../lib/i18n";
import {useEffect, useState} from "react";

export default function PageLayout({...props}) {
    const [pageLang, setPageLang] = useState<string>();
    useEffect(() => {
        setPageLang(i18n.locale || localStorage.getItem("lang") || undefined);
    }, []);

    return (
        <>
            <Helmet htmlAttributes={{lang: pageLang}} />
            <TopNav />
            <PageContainer>
                <div className={"pageContainer"}>{props.children}</div>
            </PageContainer>
            <Footer />
        </>
    );
}
