import React, {memo} from "react";
import {AppBar, Button} from "@mui/material";
import i18n, {LOCALES} from "../../lib/i18n";
import ReactCountryFlag from "react-country-flag";

type Props = {};

const FLAG_MAP: Record<string, string> = {
    no: "NO",
    en: "GB",
};

const TopNav: React.FC<Props> = () => {
    return (
        <AppBar position={"fixed"} color={"transparent"} variant={"plain" as never} className={"pageTopNav"}>
            <div style={styles.navWrapper}>
                <div style={styles.leftSection}></div>
                <div style={styles.rightSection}>
                    <Button
                        onClick={() => {
                            i18n.setlocale(LOCALES[(LOCALES.indexOf(i18n.locale) + 1) % LOCALES.length]);
                            window.location.reload();
                        }}
                    >
                        <ReactCountryFlag
                            countryCode={FLAG_MAP[i18n.locale] ?? "NO"}
                            style={{
                                fontSize: "2em",
                                lineHeight: "2em",
                            }}
                            svg={true}
                        />
                    </Button>
                </div>
            </div>
        </AppBar>
    );
};
export default memo(TopNav);

const styles = {
    navWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "15px",
        padding: "10px",
    },
    leftSection: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "15px",
        marginRight: "auto",
    },
    RightSection: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "15px",
        marginLeft: "auto",
    },
} as Record<string, React.CSSProperties>;
