import {FC} from "react";
import {ThreeDots} from "react-loader-spinner";
import {Colors} from "../../styles/colors.style";

const SPLoader: FC<{type?: "dots" | "circle-notch"; notCentered?: boolean}> = ({type, notCentered}) => {
    if (type === "circle-notch") {
        return <i className="fas fa-spin fa-circle-notch" />;
    }

    return (
        <ThreeDots
            height="80"
            width="110"
            radius="9"
            color={Colors.primary}
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass={"spLoader-container"}
            visible={true}
        />
    );
};

export default SPLoader;
